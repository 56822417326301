import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const fixedButton = (container) => {

    let btn = container.querySelector('.vacature__fixed__btn');
    let btnTriggerTop = container.querySelector('.vacature__img__content');
    let btnTriggerBot = container.querySelector('.sollicitatie__form');

    let tlTop = gsap.timeline();
    let tlBot = gsap.timeline();

    tlTop.to(btn, {scrollTrigger: {
        trigger: btnTriggerTop,
        start: 'top top',
        end: 'top top',
        scrub: true,
        markers: false,
        onEnter: () => btn.classList.add('active'),
        onLeaveBack: () => btn.classList.remove('active'),
        }});

    tlBot.to(btn, { scrollTrigger: {
        trigger: btnTriggerBot,
        start: 'bot center',
        end: 'bot center',
        scrub: true,
        markers: false,
        onEnter: () => btn.classList.remove('active'),
        onLeaveBack: () => btn.classList.add('active'),
        }});
}


export default fixedButton;
