import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const banner = (container) => {

    let tl = gsap.timeline();

    let banner = container.querySelector('.banner');
    let bannerTop = container.querySelector('.banner__content__top');
    let bannerBot = container.querySelector('.banner__content__bot');
    let bannerImg = container.querySelector('.banner__img')

    let bannerY;
    let contentX;
    if (window.innerWidth < 1499) {
        bannerY = 0;
        contentX = 100;
    } else {
        bannerY = 250;
        contentX = 400;
    }

    tl.to(bannerTop, {x: contentX, scrollTrigger: {
        trigger: banner,
        start: 'top',
        end: 'bottom',
        scrub: true,
        markers: false,
        }});

    tl.to(bannerBot, {x: -contentX, scrollTrigger: {
        trigger: banner,
        start: 'top',
        end: 'bottom',
        scrub: true,
        markers: false,
        }});


    tl.to(bannerImg, {scale: 1.2, y: bannerY, scrollTrigger: {
        trigger: banner,
        start: 'top',
        end: 'bottom',
        scrub: true,
        markers: false,
        }});

}

export default banner;
