// import external dependencies
import 'jquery';

// Import everything from autoload
import './autoload/_bootstrap.js'; import './autoload/_modernizr.js'

import barbaInit from './barbainit';

import 'custom-event-polyfill/polyfill';
import '@fancyapps/fancybox/dist/jquery.fancybox';
import 'slick-carousel/slick/slick';
import 'jquery-match-height/jquery.matchHeight';

// import local dependencies
import Router from './util/Router';
import common from './routes/common';

/** Populate Router instance with DOM routes */
let routes = {
    // All pages
    common,
};

/* eslint-disable */
import * as modules0 from './modules/templateContact.js'; import * as modules1 from './modules/templateTeam.js'; let modules = [modules0, modules1]
if (typeof (modules) !== 'undefined') {
    for (let i = 0; modules.length > i; i++) {
        let module = modules[i];
        if (module.__esModule) {
            let name = Object.keys(module)[0];
            routes[name] = module[name];
        }
    }
}
/* eslint-disable */

let router = new Router(routes);

// Load Events
jQuery(document).ready(() => {
    router.loadEvents();
    barbaInit.init();
});
