const weekAgenda = () => {
    const settings = {
        autoplay: false,
        arrows: true,
        dots: false,
        fade: false,
        slidesToShow: 1,
        useTransform: false,
        responsive: [
            {
                breakpoint: 9999,
                settings: 'unslick',
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: false,
                    arrows: true,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: false,
                    arrows: true,
                },
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    arrows: true,
                    centerMode: true,
                },
            },
        ],
    }

    $('.week-agenda__container').slick(settings);


    $(window).on('resize', function () {
        if ($(window).width() < 991 && !$('.week-agenda__container').hasClass('slick-initialized')) {
            $('.week-agenda__container').slick(settings);
        }
    });
}

export default weekAgenda;
