import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const prosCons = (container) => {

    let item = container.querySelectorAll('.pros-and-cons__item');
    let block = container.querySelectorAll('.pros-and-cons__container');

    let tl = gsap.timeline();

    tl.to(item, {autoAlpha: '1', stagger: 0.1, scrollTrigger: {
        'trigger': block,
        'start': '70% bottom',
        'end': 'bottom center',
        'scrub': true,
        'markers': false,
        }});

}

export default prosCons;
