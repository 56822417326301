import barba from '@barba/core';

// Page
import pageEnter from './animations/pageEnter'
import pageLeave from './animations/pageLeave'
import defaultJS from './pages/defaultJS';
import banner from './pages/banner';
import trapFocus from './util/trapFocus';
import joinUs from './pages/joinus';
import bannerVideo from './pages/bannerVideo';
import menu from './pages/menu';
import mockup from './pages/mockup';
import quiz from './pages/quiz';
import isotopeFilter from './pages/isotopeFilter';
import rotateText from './pages/rotateText';
import fixedButton from './pages/fixedButton';
import weekAgenda from './pages/weekAgenda';
import prosCons from './pages/prosCons';
import pageVacatureDetail from './animations/pageVacatureDetail';

export default {
    init() {
        barba.init({
            debug: true,

            // define a custom function that will prevent Barba
            // from working on links that contains a `prevent` CSS class
            prevent: ({ el }) => el.classList && el.classList.contains('prevent'),

            transitions: [
                {
                    leave: ({current}) => pageLeave(current.container),
                    enter({next}) {
                        pageEnter(next.container);
                        banner(next.container);
                        joinUs(next.container);
                    },
                    after(data) {
                        // Reset body class
                        menu(data.next.container);
                        defaultJS();
                        trapFocus(data);
                    },
                    once(data) {
                        pageEnter(data.next.container);
                        banner(data.next.container);
                        joinUs(data.next.container);
                        menu(data.next.container);
                        trapFocus(data);
                    },
            },
            {
                name: 'home',
                to: {
                    namespace: ['home'],
                },
                leave: ({current}) => pageLeave(current.container),
                    enter({next}) {
                        pageEnter(next.container);
                        banner(next.container);
                        joinUs(next.container);
                        bannerVideo(next.container);
                        mockup(next.container);
                        quiz(next.container);
                        isotopeFilter(next.container);
                },
                    after(data) {
                        // Reset body class
                        menu(data.next.container);
                        defaultJS();
                        trapFocus(data);
                },
                    once(data) {
                        pageEnter(data.next.container);
                        banner(data.next.container);
                        joinUs(data.next.container);
                        menu(data.next.container);
                        bannerVideo(data.next.container);
                        mockup(data.next.container);
                        quiz(data.next.container);
                        isotopeFilter(data.next.container);
                        trapFocus(data);
                },
            },
            {
                name: 'vacatures',
                to: {
                    namespace: ['vacatures'],
                },
                leave: ({current}) => pageLeave(current.container),
                enter({next}) {
                    pageEnter(next.container);
                    banner(next.container);
                    isotopeFilter(next.container);
                },
                after(data) {
                    // Reset body class
                    menu(data.next.container);
                    defaultJS();
                    trapFocus(data);
                },
                once(data) {
                    pageEnter(data.next.container);
                    banner(data.next.container);
                    menu(data.next.container);
                    isotopeFilter(data.next.container);
                    trapFocus(data);
                },
            },
            {
                name: 'vacature__detail',
                to: {
                    namespace: ['vacature__detail'],
                },
                leave: ({current}) => pageLeave(current.container),
                enter({next}) {
                    location.reload();
                    pageEnter(next.container);
                    banner(next.container);
                    bannerVideo(next.container);
                    pageVacatureDetail(next.container);
                    joinUs(next.container);
                    rotateText(next.container);
                    fixedButton(next.container);
                    weekAgenda(next.container);
                    prosCons(next.container);
                },
                after(data) {
                    // Reset body class
                    menu(data.next.container);
                    defaultJS();
                    trapFocus(data);
                },
                once(data) {
                    pageEnter(data.next.container);
                    banner(data.next.container);
                    bannerVideo(data.next.container);
                    pageVacatureDetail(data.next.container);
                    joinUs(data.next.container);
                    menu(data.next.container);
                    rotateText(data.next.container);
                    fixedButton(data.next.container);
                    weekAgenda(data.next.container);
                    prosCons(data.next.container);
                    trapFocus(data);
                },
            },

            {
                name: 'contact',
                to: {
                    namespace: ['contact'],
                },
                leave: ({current}) => pageLeave(current.container),
                enter({next}) {
                    location.reload();
                    pageEnter(next.container);
                    banner(next.container);
                    joinUs(next.container);
                },
                after(data) {
                    // Reset body class
                    menu(data.next.container);
                    defaultJS();
                    trapFocus(data);
                },
                once(data) {
                    pageEnter(data.next.container);
                    banner(data.next.container);
                    menu(data.next.container);
                    joinUs(data.next.container);
                    trapFocus(data);
                },
            },
            ],
        });
    },
}
