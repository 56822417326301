import gsap from 'gsap';

const menu = (container) => {
    if (container.querySelector('.banner') === null || container.querySelector('.menu') === null) {
        return;
    }

    let headerMenu = container.querySelector('.banner').querySelector('.menu__trigger');
    headerMenu.addEventListener('click', showMenu);

    let headerMenuClose = container.querySelector('.menu').querySelector('.menu__trigger');
    headerMenuClose.addEventListener('click', hideMenu);

    let menu = container.querySelector('.menu');
    let menuLeft = container.querySelector('.menu__left');
    let menuRight = container.querySelector('.menu__right');
    // let menuLogo = container.querySelector('.menu__left__logo');
    let menuCount = container.querySelector('.menu__left__count');
    let menuItems = menuLeft.querySelectorAll('.menu-item');
    let menuClose = menu.querySelector('.menu__trigger');
    let menuRightItem = container.querySelectorAll('.menu__right__item');
    let menuRightOuter = container.querySelectorAll('.menu__right__outer');


    function showMenu() {

        document.body.classList.add('body__menu__open');

        const tl = gsap.timeline();

        let menuItemsX,
            menuWith,
            menuDelay;

        if (window.innerWidth < 1499) {
            menuItemsX = '-40px';
        } else {
            menuItemsX = '-100px';
        }

        if (window.innerWidth < 768) {
            menuWith = '100%';
            menuDelay = '0';
        } else {
            menuWith = '50%';
            menuDelay = '>=+0.2';
        }

        tl
            .to(menu, {pointerEvents: 'all', display: 'block'}, 0)
            .to(menuLeft, {pointerEvents: 'all', autoAlpha: 1, duration: 0, ease: 'none)'}, 0)
            .to(menuLeft, {width: menuWith, duration: 0.6, ease: 'power4.out'}, 0)
            .to(menuRight, {pointerEvents: 'all', autoAlpha: 1, duration: 0, ease: 'none)'}, 0)
            .to(menuRight, {width: menuWith, duration: 0.6, ease: 'power4.out'}, menuDelay)
            // .to(menuLogo, { x: '-100px', autoAlpha: 1, duration: 0.4, ease: 'Power4.out'}, '>=-0.3')
            .to(menuItems, {x: '-100px', autoAlpha: 1, duration: 0.4, stagger: 0.2, ease: 'Power4.out'}, '>')
            .to(menuCount, {scale: 1, duration: 1, ease: 'elastic.out(1,0.3)'}, '>')
            .to(menuClose, {pointerEvents: 'all', autoAlpha: 1, duration: 0.4}, '>-=1.2')
            .to(menuRightItem, {y: menuItemsX, autoAlpha: 1, duration: 0.4, stagger: 0.2, ease: 'Power4.out'}, '>-=0.2')
    }

    function hideMenu() {

        document.body.classList.remove('body__menu__open');

        const tl = gsap.timeline();
        if (window.innerWidth < 768) {
            tl
                .to(headerMenu, {pointerEvents: 'none'}, 0)
                .to(menu, {x: '-100%', duration: 0.6, ease: 'power4.out'}, 0)
        } else {
            tl
                .to(headerMenu, {pointerEvents: 'none'}, 0)
                .to(menuRightOuter, {autoAlpha: '0', duration: 0.4, ease: 'power4.out'}, 0)
                .to(headerMenuClose, {autoAlpha: '0', duration: 0.4, ease: 'power4.out'}, 0.2)
                .to(menuItems, {autoAlpha: '0', duration: 0.4, ease: 'power4.out'}, 0.2)
                // .to(menuLogo, { autoAlpha: '0', duration: 0.4, ease: 'power4.out'}, 0.2)
                .to(menuRight, {x: '-50vw', width: '100%', duration: 0, ease: 'power4.out'}, 0.5)
                .to(menuRight, {width: '0', duration: 0.9, ease: 'power4.out'}, '>')
                .to(menuLeft, {width: '0', duration: 0.6, ease: 'power4.out'}, '>=-0.9')
        }

        tl
            .set(headerMenu, {clearProps: 'all'}, '>=+0.4')
            .set(headerMenuClose, {clearProps: 'all'}, '>')
            .set(menu, {clearProps: 'all'}, '>')
            .set(menuLeft, {clearProps: 'all'}, '>')
            .set(menuRight, {clearProps: 'all'}, '>')
            .to(menu, {display: 'none'}, '>')
            // .set(menuLogo, { clearProps: 'all'}, '>')
            .set(menuCount, {clearProps: 'all'}, '>')
            .set(menuItems, {clearProps: 'all'}, '>')
            .set(menuClose, {clearProps: 'all'}, '>')
            .set(menuRightItem, {clearProps: 'all'}, '>')
            .set(menuRightOuter, {clearProps: 'all'}, '>')
            .to(headerMenu, {pointerEvents: 'all'}, '>=+0.6')
    }
}

export default menu;
