import { ScrollTrigger } from 'gsap/ScrollTrigger';

const defaultJS = () => {

    // Refresh/reset scrolltrigger
    ScrollTrigger.refresh();

    // set bodyclass to correct page
    let bodyClassList = document.getElementById('body__class').classList;
    let body = document.getElementsByTagName('body')
    body[0].className = bodyClassList;

    // Scroll to top
    window.scrollTo(0,0)
}

export default defaultJS;
