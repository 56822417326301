import gsap from 'gsap';

const pageEnter = (container) => {
    // Hide skip tp content button if there is no main content
    const mainContentElement = $('#skip-to-content-anchor');
    const btnSkipToContent = $('.btn--skip');

    if (mainContentElement.length === 0) {
        btnSkipToContent.hide();
    }

    $(document).ready(footerMarginTop);
    $(window).on('resize',footerMarginTop);

    let refererPopupButton = document.querySelector('.referer__popup__button');
    if (refererPopupButton && container.classList.contains('barba__home')) {
        setTimeout(() => {
            refererPopupButton.click();
        }, 500);
    }

    function footerMarginTop()
    {
        setTimeout(function () {
            let footer_height = $('.footer').outerHeight();
            $('.main__content').css('margin-bottom', footer_height);
        }, 300);
    }

    $('a[href*=\\#]').on('click', function (e) {
        e.preventDefault();
        $('html,body').animate({scrollTop: $(this.hash).offset().top - 100}, 'slow');
    });

    $('.btn--readmore').click(function () {
        $(this).toggleClass('btn--readmore--none');
        $('.vacature__intro__content').toggleClass('vacature__intro__content--open');
    });

    if ($('.video__default').length) {
        $('.video__default').get(0).play();

        $('.error__video__mute').click(function () {
            if ( $('.video__default').prop('muted') ) {
                $('.video__default').prop('muted', false);
                $('.error__video__mute').addClass('unmuted');
            } else {
                $('.video__default').prop('muted', true);
                $('.error__video__mute').removeClass('unmuted');
            }
        });

        $('.error__video__pause').click(function () {
            let video = document.querySelector('.video__default');
            if ( video.paused ) {
                $('.video__default').get(0).play();
                $('.error__video__pause').removeClass('unpause');
            } else {
                $('.video__default').get(0).pause();
                $('.error__video__pause').addClass('unpause');
            }
        });
    }


    let gif = container.querySelectorAll('.gif__hover');

    if (gif.length > 0) {
        gif.forEach((item) => {
            let href = item.getAttribute('href');
            item.removeAttribute('href');
            let text = item.textContent;
            let createSpan = document.createElement('span')
            createSpan = createSpan.innerHTML = '<span>'+text+'<img src="'+href+'" alt="GIF van https://giphy.com/" class="gif__hover__img"></span>'
            item.innerHTML = createSpan;
        });
    }

    const tl = gsap.timeline();

    let body = container;

    tl
        .to(body, { pointerEvents: 'none', userSelect: 'none' })
        .to(body, { autoAlpha: '1', duration: 0.3, ease: 'none'})
        .to(body, {pointerEvents: 'all', userSelect: 'auto'})

    return tl;

}

export default pageEnter;
