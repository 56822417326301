import gsap from 'gsap';

const banner = (container) => {

    let video = container.querySelector('.video');


    let videoButton = container.querySelector('.video__button');
    if (video != null) {
        videoButton.addEventListener('click', rotate);
        videoButton.addEventListener('mouseenter', rotateHover);
    }

    let videoButtonLine1 = container.querySelector('.video__button__line1');
    let videoButtonLine2 = container.querySelector('.video__button__line2');
    let videoShort = container.querySelector('.video__banner__short');
    let videoLong = container.querySelector('.video__banner__long');
    let videoLeft = container.querySelector('.video__bg__left');
    let videoRight = container.querySelector('.video__bg__right');
    let videoMute = container.querySelector('.video__mute');
    let videoPause = container.querySelector('.video__pause');

    if (video != null) {
        videoButton.addEventListener('click', rotate);
        videoButton.addEventListener('mouseenter', rotateHover);

        videoLong.addEventListener('click', playPause);
        videoPause.addEventListener('click', playPause);
        videoMute.addEventListener('click', muted);

        videoShort.muted = true;
        videoShort.play();
        videoShort.loop = true;

        let tl = gsap.timeline();

        tl
            .to(video, {autoAlpha: '1', y: '0'}, 0.8)
    }

    function rotate()
    {
        let tl = gsap.timeline();

        tl
            .to(videoButtonLine1, {rotation: '-180', duration: 0.3 }, 0)
            .to(videoButtonLine2, {rotation: '-180', duration: 0.3 }, 0)
            .to(videoButtonLine1, {height: '100%', marginTop: '0', top: '0' }, '>')
            .to(videoButtonLine2, {height: '100%', marginTop: '0', top: '0' }, '>')
            .to(videoButton, {autoAlpha: '0' }, '>=-0.3')
            .to(videoLeft, {left: '-50%', marginTop: '0', top: '0', duration: 0.5 }, '<>>')
            .to(videoButtonLine1, {left: '0', duration: 0.5}, '<')
            .to(videoRight, {left: '100%', marginTop: '0', top: '0', duration: 0.5 }, '<')
            .to(videoButtonLine2, {left: '100%', duration: 0.5}, '<')
            .add(function () {
                videoLong.play();
                videoLong.volume = 1;
            })
            .to(videoShort, { autoAlpha: 0})
            .to(videoLong, { zIndex: '4'})
            .set(videoPause, { clearProps: 'all'})
            .to(videoPause, { autoAlpha: '1'}, '<')
            .set(videoMute, { clearProps: 'all'})
            .to(videoMute, { autoAlpha: '1'}, '<')
            .to(videoButtonLine1, { autoAlpha: 0})
            .to(videoButtonLine2, { autoAlpha: 0})
    }



    function rotateHover()
    {
        let tl = gsap.timeline();

        tl
            .to(videoButtonLine1, {height: '60%', top: '45%'})
    }

    function playPause()
    {
        videoPause.classList.toggle('hide');

        if (videoLong.paused) {
            videoLong.play();
        } else {
            videoLong.pause();
        }
    }

    function muted()
    {
        videoMute.classList.toggle('hide');
        if (videoLong.muted) {
            videoLong.muted = false;
        } else {
            videoLong.muted = true;
        }
    }



}

export default banner;
