import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const rotateText = (container) => {

    let rotateVideo = container.querySelector('.collega-video__src');
    if(rotateVideo != null) {
        rotateVideo.play();
        rotateVideo.muted = true;
        rotateVideo.autoplay = true;
    }

    let tl = gsap.timeline();

    let rotateElement = container.querySelector('.collega-video__rotate');
    let rotateTrigger = container.querySelector('.collega-video-container');


    tl.to(rotateElement, {rotate: '360deg', scrollTrigger: {
        'trigger': rotateTrigger,
        'start': 'top bottom',
        'end': 'bottom top',
        'scrub': true,
        'markers': false,
        }});
}


export default rotateText;
