import Isotope from 'isotope-layout/js/isotope';
import imagesLoaded from 'imagesloaded/imagesloaded'

const isotopeFilter = (container) => {
    let selectorGrid = container.querySelectorAll('.filter__vacatures__grid');

    if (selectorGrid.length !== 0) {
        imagesLoaded(container.querySelector('.filter__vacatures__grid') , function() {
            const grid = container.querySelector('.filter__vacatures__grid');
            const iso = new Isotope( grid, {
                itemSelector: '.filter__vacatures__grid__item',
                layoutMode: 'fitRows',
                percentPosition: true,
                masonry: {
                    columnWidth: '.filter__vacatures__grid__item',
                },
            });

            let filters = container.querySelector('.filter__vacatures__categories');
            filters.addEventListener( 'click', function( event ) {
                // only work with buttons
                if ( !event.target.matches('button')) {
                    return;
                }

                var filterValue = event.target.getAttribute('data-filter');
                // use matching filter function
                iso.arrange({ filter: filterValue });
            });

            let buttonGroups = container.querySelectorAll('.filter__vacatures__categories');
            for ( let i=0, len = buttonGroups.length; i < len; i++ ) {
                let buttonGroup = buttonGroups[i];
                radioButtonGroup( buttonGroup );
            }

            function radioButtonGroup( buttonGroup ) {
                buttonGroup.addEventListener( 'click', function( event ) {
                    // only work with buttons
                    if ( !event.target.matches('button')) {
                        return;
                    }
                    buttonGroup.querySelector('.is-checked').classList.remove('is-checked');
                    event.target.classList.add('is-checked');
                });
            }

        });
    }
}

export default isotopeFilter;
