import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const pageVacatureDetail = (container) => {
    let video = container.querySelector('.vacature__img__container');
    let tl = gsap.timeline();

    tl
        .to(video, {autoAlpha: '1', y: '0'}, 0.8)

}

export default pageVacatureDetail;
