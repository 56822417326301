import gsap from 'gsap';

const pageEnter = (container) => {

    const tl = gsap.timeline();

    let body = container;

    tl
        .to(body, { pointerEvents: 'none', userSelect: 'none'})
        .to(body, { autoAlpha: '0', duration: 0.3, ease: 'none'}, 0)

    return tl;

}

export default pageEnter;
