import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const banner = (container) => {

    let wrapper = container.querySelector('.mockup');
    let nextBtn = container.querySelector('.mockup__next');
    let first  = container.querySelector('.mockup__inner:first-child')

    first.style.opacity = '1';
    first.classList.add('mockup__active');
    wrapper.style.backgroundColor = first.getAttribute('data-color');

    nextBtn.addEventListener('click', nextImage)

    function nextImage()
    {
        let currentItem = container.querySelector('.mockup__active');
        let currentDataMockup = currentItem.getAttribute('data-mockup');

        if (currentDataMockup == 2) {
            nextItem = first;
        } else {
            nextItem = currentItem.nextElementSibling;
        }

        let currentText = currentItem.querySelector('.mockup__text');
        let currentImg = currentItem.querySelector('.mockup__img');

        let nextItem;

        setTimeout(function () {
            currentItem.classList.remove('mockup__active');
            nextItem.classList.add('mockup__active');
        },600)

        let nextColor = nextItem.getAttribute('data-color');
        let nextText = nextItem.querySelector('.mockup__text');
        let nextImg = nextItem.querySelector('.mockup__img');



        let tl = gsap.timeline();

        tl
            .to(currentText, {x: '-300px', scale: '1.1', autoAlpha: 0, duration: 0.6, ease: 'Power4.out' }, 0)
            .to(currentImg, {x: '300px', scale: '1.1', autoAlpha: 0, duration: 0.6, ease: 'Power4.out' }, 0)
            .to(wrapper, {backgroundColor: nextColor, duration: 0.6, ease: 'Power4.out' }, 0)
            .fromTo(nextText, {x: '-300px', scale: '1.1', autoAlpha: 0, ease: 'Power4.out'}, {x: '0', scale: '1', autoAlpha: 1}, '>')
            .fromTo(nextImg, {x: '300px', scale: '1.1', autoAlpha: 0, ease: 'Power4.out'}, {x: '0', scale: '1', autoAlpha: 1}, '<')


    }


}

export default banner;
