import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

let clientWidth = document.documentElement.clientWidth;

window.addEventListener('resize', function () {
    clientWidth = document.documentElement.clientWidth;
});

const joinUs = (container) => {
    let tl = gsap.timeline();
    let joinUsTitle = container.querySelector('.join-us__title');

    if (clientWidth <= 768) {
        tl.from(joinUsTitle, {xPercent: 50, yPercent: -50}).to(joinUsTitle, {xPercent: -200, yPercent: -50, scrollTrigger: {
            trigger: joinUsTitle,
            start: 'top bottom',
            end: 'bottom top',
            scrub: true,
            markers: false,
            },
        });
    }
}

export default joinUs;
