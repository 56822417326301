const trapFocus = (data) => {
    let focusableElements = null;
    let firstFocusableEl = null;
    let lastFocusableEl = null;

    const footerLink = document.querySelector('.footer a');

    if (footerLink) {
        footerLink.addEventListener('focusin', function () {
            document.querySelector('#body__class').scrollIntoView({block: 'start', behavior: 'smooth'});
        });
    }

    if ( data.next.container.querySelector('.banner') === null || data.next.container.querySelector('.menu') === null) {
        return;
    }

    data.next.container.querySelector('.banner .menu__trigger').addEventListener('click', function () {
        trapFocusCheck(data.next.container.querySelector('.menu'));
    });

    data.next.container.querySelector('.menu .menu__trigger').addEventListener('click', function (e) {
        focusableElements = null;
        firstFocusableEl = null;
        lastFocusableEl = null;
        document.querySelector('.banner .menu__trigger').focus();
        e.preventDefault();
    });

    function trapFocusCheck(element) {
        focusableElements = element.querySelectorAll('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])');
        firstFocusableEl = focusableElements[0];
        lastFocusableEl = focusableElements[focusableElements.length - 1];
        const KEYCODE_TAB = 9;

        setTimeout(function () {
            firstFocusableEl.focus()
        }, 1000);

        element.addEventListener('keydown', function (e) {
            const isTabPressed = (e.key === 'Tab' || e.keyCode === KEYCODE_TAB);

            if (!isTabPressed) {
                return;
            }

            if (e.shiftKey) {
                if (document.activeElement === firstFocusableEl) {
                    lastFocusableEl.focus();
                    e.preventDefault();
                }
            } else /* tab */ {
                if (document.activeElement === lastFocusableEl) {
                    firstFocusableEl.focus();
                    e.preventDefault();
                }
            }
        });
    }
};

export default trapFocus;
