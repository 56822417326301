import gsap from 'gsap';

const quiz = (container) => {
    let nsfwContent = container.querySelectorAll('.front__quiz__content');
    if (nsfwContent.length !== 0) {

        //NSFW
        let nsfwButton = container.querySelector('.front__quiz__content__nsfw');
        let supriseVideo = container.querySelector('.front__quiz__content__nsfw__suprise__video');
        let suprise = container.querySelector('.front__quiz__content__nsfw__suprise');
        let toggleButton = container.querySelector('.front__quiz__content__nsfw__toggle');
        let toggleButtonCircle = container.querySelector('.front__quiz__content__nsfw__toggle__circle');

        nsfwButton.addEventListener('click', () => {
            if (supriseVideo.paused) {
                supriseVideo.currentTime = 0;
                supriseVideo.play()
            } else {
                supriseVideo.currentTime = 0;
                supriseVideo.pause()
            }

            toggleButton.classList.toggle('front__quiz__content__nsfw__toggle--active');
            toggleButtonCircle.classList.toggle('front__quiz__content__nsfw__toggle__circle--active');
            suprise.classList.toggle('front__quiz__content__nsfw__suprise--active');
        });

        supriseVideo.addEventListener('ended', () => {
            supriseVideo.currentTime = 0;
            toggleButton.classList.toggle('front__quiz__content__nsfw__toggle--active');
            toggleButtonCircle.classList.toggle('front__quiz__content__nsfw__toggle__circle--active');
            suprise.classList.toggle('front__quiz__content__nsfw__suprise--active');
        },false);

        supriseVideo.addEventListener('loadedmetadata', () => {
            supriseVideo.currentTime = 0;
        }, false);

        //QUIZ
        const Garagebox = window.sessionStorage;
        let quizItems = container.querySelectorAll('.front__quiz__content__questions__item');
        let quizLoading = container.querySelector('.front__quiz__content__questions__loading');
        let currentCount = container.querySelector('.front__quiz__content__questions__count__current');
        let questionsContainer = container.querySelector('.front__quiz__content__questions');
        let resultsContainer = container.querySelectorAll('.front__quiz__content__questions__results');
        let resultsCountContainer = container.querySelector('.front__quiz__content__questions__results__amount__count');

        let correctAnswers = Number(Garagebox.getItem('correctAnswers'));
        let currentQuestion = Number(Garagebox.getItem('currentQuestion'));
        let lastQuestion = Garagebox.getItem('lastQuestion');

        //sessionStorage.clear();

        //Set defaults
        if (!correctAnswers) {
            Garagebox.setItem('correctAnswers', 0);
        }

        if (!currentQuestion) {
            Garagebox.setItem('currentQuestion', 0);
        }

        if (!lastQuestion) {
            Garagebox.setItem('lastQuestion', false);
        }

        //Show current question
        currentCount.innerHTML = currentQuestion+1;

        const quizTimeline = gsap.timeline();
        quizTimeline.to(quizLoading, { autoAlpha: 0 });

        quizTimeline.eventCallback('onComplete', () => {
            const gotoTimeline = gsap.timeline();

            quizItems.forEach((quizItem, index) => {

                //Init last question
                if(currentQuestion === 9 && lastQuestion !== false) {
                    resultsCountContainer.innerHTML = correctAnswers;
                    let FinalMessageContainer = container.querySelector(getMessageClass(correctAnswers));
                    gotoTimeline.to(FinalMessageContainer, {display: 'block'});

                    gotoTimeline.to(questionsContainer, {minHeight: resultsContainer.offsetHeight});
                    gotoTimeline.to(quizLoading, {position: 'absolute'});
                    gotoTimeline.to(resultsContainer, {autoAlpha: 1, position: 'relative', zIndex: 25});
                } else {
                    if(index === currentQuestion) {
                        gotoTimeline.to(questionsContainer, {minHeight: quizItems[currentQuestion].offsetHeight});
                        gotoTimeline.to(quizLoading, {position: 'absolute'});
                        gotoTimeline.to(quizItems[currentQuestion], {display: 'flex', autoAlpha: 1, position: 'relative', zIndex: 25});
                        quizItems[currentQuestion].querySelector('.front__quiz__content__questions__video').play();
                    }
                }

                let anwserButtons = quizItem.querySelectorAll('.front__quiz__content__questions__anwser');

                let clickAllowed = true;

                for (let i = 0; i < anwserButtons.length; i++) {
                    anwserButtons[i].addEventListener('click', () => {
                        if (!clickAllowed) {
                            return;
                        }

                        clickAllowed = false;

                        //Check answer
                        if(Number(anwserButtons[i].dataset.answer) === 1) {
                            correctAnswers++;
                            Garagebox.setItem('correctAnswers', correctAnswers);
                            anwserButtons[i].classList.add('front__quiz__content__questions__anwser--true');
                        } else {
                            anwserButtons[i].classList.add('front__quiz__content__questions__anwser--false');
                        }

                        if(currentQuestion === 9) {
                            resultsCountContainer.innerHTML = correctAnswers;
                            let FinalMessageContainer = container.querySelector(getMessageClass(correctAnswers));
                            gotoTimeline.to(FinalMessageContainer, {display: 'block'});

                            gotoTimeline.to(questionsContainer, {minHeight: resultsContainer.offsetHeight});
                            gotoTimeline.to(quizItems[currentQuestion], {autoAlpha: 0, duration: 0.3});
                            gotoTimeline.to(quizItems[currentQuestion], {position: 'absolute', zIndex: -1, duration: 0});
                            gotoTimeline.to(resultsContainer, {position: 'relative', zIndex: 25, duration: 0});
                            gotoTimeline.to(resultsContainer, {autoAlpha: 1, duration: 0.3});
                            Garagebox.setItem('lastQuestion', true);
                        } else {
                            //increase question count
                            currentQuestion++;
                            Garagebox.setItem('currentQuestion', currentQuestion);
                            currentCount.innerHTML = currentQuestion+1;

                            //goto next question
                            gotoTimeline.to(questionsContainer, {minHeight: quizItems[currentQuestion].offsetHeight});
                            gotoTimeline.to(quizItems[currentQuestion-1], {autoAlpha: 0, duration: 0.3});
                            gotoTimeline.to(quizItems[currentQuestion-1], {position: 'absolute', zIndex: -1, duration: 0});
                            gotoTimeline.to(quizItems[currentQuestion], {display: 'flex', position: 'relative', zIndex: 25, duration: 0});
                            gotoTimeline.to(quizItems[currentQuestion], {autoAlpha: 1, duration: 0.3});
                            quizItems[currentQuestion].querySelector('.front__quiz__content__questions__video').play();
                            clickAllowed = true;
                        }
                    });
                }
            });
        });
    }

    function getMessageClass(correctAnswers) {
        switch(correctAnswers) {
            case 0:
            case 1:
            case 2:
                return '.front__quiz__content__questions__results__item_1';
            case 3:
            case 4:
                return '.front__quiz__content__questions__results__item_2';
            case 5:
            case 6:
                return '.front__quiz__content__questions__results__item_3';
            case 7:
            case 8:
                return '.front__quiz__content__questions__results__item_4';
            case 9:
            case 10:
                return '.front__quiz__content__questions__results__item_5';
        }
    }
}

export default quiz;
